import React, { useEffect } from "react";
import "../../../stylesheets/paidForm.css";
import { findCompanyDetails } from "../../../actions/companyDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaidFormLogoSection from "../../common/paidComponents/paidFormLogoSection";
import { HANDLER_ORDER_NUMBER } from "../../../reducers/orderNumberReducer";
import { HANDLER_RESTAURANT_NAME } from "../../../reducers/restaurantNameReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../../reducers/restaurantAddressReducer";
import { HANDLER_EXPECTED_PICKUP_TIME } from "../../../reducers/expectedPickupTimeReducer";
import { HANDLER_CUSTOMER_NAME } from "../../../reducers/customerNameReducer";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../../reducers/customerPhoneNumberReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../../reducers/customerAddressReducer";
import { HANDLER_EXPECTED_DELIVERY_TIME } from "../../../reducers/expectedDeliveryTimeReducer";
import { HANDLER_EXPECTED_DELIVERY_DATE } from "../../../reducers/expectedDeliveryDateReducer";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../../reducers/deliveryInstructionReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../../reducers/customerEmailReducer";
import { HANDLER_TIPS } from "../../../reducers/tipsReducer";
import { HANDLER_DELIVERY_FEE } from "../../../reducers/deliveryFeeReducer";
import { HANDLER_PAYMENT_METHOD } from "../../../reducers/paymentMethodReducer";
import i18next from "i18next";
import { useStripePaidForm } from "../../customHooks/stripePaidFormHook";
import StripeNotConnected from "../../common/paidComponents/stripeNotConnected";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../../resources/locale/keys";
import { DATE_INPUT_FIELD, EMAIL_INPUT_FIELD, TEXT_INPUT_FIELD, TEXTAREA_ADDRESS_FIELD, TIME_INPUT_FIELD, VALUE_INPUT_FIELD } from "../../../util/formBuilderHelper";
import GenericOrderDetailsInputBuilder from "../../common/paidFormBuilders/genericOrderDetailsInputBuilder";
import { HANDLER_NUMBER_OF_ITEMS } from "../../../reducers/numberOfItemsReducer";
import { HANDLER_NUMBER_OF_EXTRA_ITEMS } from "../../../reducers/numberOfExtraItemsReducer";
import { HANDLER_NUMBER_OF_EXTRA_ITEMS_2 } from "../../../reducers/numberOfExtraItems2Reducer";
import CustomOrderPaymentPageBuilder from "./customOrderPaymentPageBuilder";

const configJSON = [HANDLER_ORDER_NUMBER, HANDLER_RESTAURANT_NAME, HANDLER_RESTAURANT_PHONE_NUMBER, HANDLER_RESTAURANT_ADDRESS, HANDLER_EXPECTED_PICKUP_TIME, HANDLER_CUSTOMER_NAME, HANDLER_CUSTOMER_PHONE_NUMBER, HANDLER_CUSTOMER_EMAIL, HANDLER_CUSTOMER_ADDRESS, HANDLER_EXPECTED_DELIVERY_TIME, HANDLER_EXPECTED_DELIVERY_DATE,
    HANDLER_DELIVERY_INSTRUCTION, HANDLER_TIPS, HANDLER_DELIVERY_FEE, HANDLER_PAYMENT_METHOD]

// for BGdeliverytruck@yahoo.com

const CustomPaidForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();

    const companyInfo = useSelector(state => state.companyDetails);
    const [stripeDetails, isEstimating, estimationComplete, estimate, resetEstimation] = useStripePaidForm(companyInfo);

    const showPayment = estimationComplete;
    const showSpinner = isEstimating;

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, [params.id])

    useEffect(() => {
        if (companyInfo !== null)
            i18next.changeLanguage(companyInfo.getLanguage());
    }, [companyInfo?.getLanguage()]);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        estimate();
    }

    const handleBackToEdit = (ev) => {
        ev.preventDefault();
        resetEstimation();
    }


    if (stripeDetails === null || companyInfo === null) return <></>;

    const config = [
        {
            sectionHeader: {title: t(FORM_LOCALE_KEYS.PICK_UP_FORM)},
            sectionFields: [
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_PICKUP),
                    required: true,
                    handlerType: HANDLER_RESTAURANT_NAME,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_PICKUP),
                    required: true,
                    handlerType: HANDLER_RESTAURANT_PHONE_NUMBER,
                },
                {
                    fieldType: TEXTAREA_ADDRESS_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_PICKUP),
                    required: true,
                    handlerType: HANDLER_RESTAURANT_ADDRESS,
                },
                {
                    fieldType: TIME_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.EXPECTED_PICKUP_TIME),
                    required: true,
                    handlerType: HANDLER_EXPECTED_PICKUP_TIME,
                },
            ]
        },
        {
            sectionHeader: {title: t(FORM_LOCALE_KEYS.DELIVER_TO)},
            sectionFields: [
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.FULL_NAME_OF_RECIPIENT),
                    required: true,
                    handlerType: HANDLER_CUSTOMER_NAME,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.PHONE_NUMBER_OF_RECIPIENT),
                    required: true,
                    handlerType: HANDLER_CUSTOMER_PHONE_NUMBER,
                },
                {
                    fieldType: EMAIL_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.EMAIL_ADDRESS_OF_RECIPIENT),
                    required: false,
                    handlerType: HANDLER_CUSTOMER_EMAIL,
                },
                {
                    fieldType: TEXTAREA_ADDRESS_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.STREET_ADDRESS_OF_RECIPIENT),
                    required: true,
                    handlerType: HANDLER_CUSTOMER_ADDRESS,
                },
                {
                    fieldType: DATE_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_DATE),
                    required: true,
                    handlerType: HANDLER_EXPECTED_DELIVERY_DATE,
                },
                {
                    fieldType: TIME_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.EXPECTED_DELIVERY_TIME),
                    required: true,
                    handlerType: HANDLER_EXPECTED_DELIVERY_TIME,
                },
            ]
        },
        {
            sectionHeader: {title: t(FORM_LOCALE_KEYS.ORDER_DETAILS)},
            sectionFields: [
                {
                    fieldType: VALUE_INPUT_FIELD,
                    placeholder: "Box Count",
                    required: true,
                    handlerType: HANDLER_NUMBER_OF_ITEMS,
                },
                {
                    fieldType: VALUE_INPUT_FIELD,
                    placeholder: "Pallet Count",
                    required: true,
                    handlerType: HANDLER_NUMBER_OF_EXTRA_ITEMS,
                },
                {
                    fieldType: VALUE_INPUT_FIELD,
                    placeholder: "Other Items Count",
                    required: true,
                    handlerType: HANDLER_NUMBER_OF_EXTRA_ITEMS_2,
                },
                {
                    fieldType: VALUE_INPUT_FIELD,
                    placeholder: t(FORM_LOCALE_KEYS.DRIVER_TIPS),
                    required: false,
                    handlerType: HANDLER_TIPS,
                },
                {
                    fieldType: TEXT_INPUT_FIELD,
                    placeholder: "Vehicle Type",
                    required: false,
                    handlerType: HANDLER_DELIVERY_INSTRUCTION,
                },

            ]
        },
    ]

    if(!stripeDetails.isConnected()) return (
        <StripeNotConnected>
            <GenericOrderDetailsInputBuilder config={config} visible={!showPayment} loading={showSpinner}/>
        </StripeNotConnected>
    );

    return (
        <div id="mainDiv" className="paidForm pb-5 w-100">
            <PaidFormLogoSection showEdit={showPayment} backToEdit={handleBackToEdit}/>
            <div className="container-md">
                <form className={`col-lg-6 offset-lg-3 pb-5 ${showPayment ? "d-none" : ""}`} onSubmit={handleSubmit}>
                    <GenericOrderDetailsInputBuilder config={config} visible={!showPayment} loading={showSpinner}/>
                </form>
            </div>
            <div id="paymentSection" className={`container-md ${showPayment ? "" : "d-none"}`}>
                <div className="col-lg-6 offset-lg-3">
                    <CustomOrderPaymentPageBuilder config={config} visible={showPayment}/>
                </div>
            </div>
        </div>
    )
}

export default CustomPaidForm;
