import {HANDLER_PICKUP_ADDRESS_TYPE} from "../reducers/pickupAddressTypeReducer";
import {HANDLER_DELIVERY_ADDRESS_TYPE} from "../reducers/deliveryAddressTypeReducer";
import {dateFormatYYYYFirst, dateTimeFormatterToUTC, defaultDeliveryTime, defaultPickUpTime} from "./defaultTimeHelper";

export const EMAIL_INPUT_FIELD = "EMAIL_INPUT_FIELD";
export const TEXT_INPUT_FIELD = "TEXT_INPUT_FIELD";
export const VALUE_INPUT_FIELD = "VALUE_INPUT_FIELD";
export const TEXTAREA_FIELD = "TEXTAREA_FIELD";
export const TEXTAREA_ADDRESS_FIELD = "TEXTAREA_ADDRESS_FIELD";
export const DATE_INPUT_FIELD = "DATE_INPUT_FIELD";
export const TIME_INPUT_FIELD = "TIME_INPUT_FIELD";
export const TIME_24H_INPUT_FIELD = "TIME_24H_INPUT_FIELD";
export const SELECTOR_INPUT_FIELD = "SELECTOR_INPUT_FIELD";
export const SECTION_HEADER = "SECTION_HEADER";
export const REMEMBER_SECTION = "REMEMBER_SECTION";
export const LAYOUT_TANGLE_WITH_NEXT = "LAYOUT_TANGLE_WITH_NEXT";
export const LAYOUT_HALF_SPACED_SINGLE = "LAYOUT_HALF_SPACED_SINGLE";
export const LAYOUT_DEFAULT = "LAYOUT_DEFAULT";

export const fieldJSONCreator = (fieldType, label, placeholder, required = false, layout = LAYOUT_DEFAULT, handlerType, selectionOptions, prepopulate = false, additionalFunction, additionalText) => {
    return {
        fieldType: fieldType,
        label: label,
        placeholder: placeholder,
        required: required,
        layout: layout,
        handlerType: handlerType,
        selectionOptions: selectionOptions,
        prepopulate: prepopulate,
        additionalFunction: additionalFunction,
        additionalText: additionalText
    }
}

export const sectionTitleJSONCreator = title => {
    return {title: title}
}

export const getAllHandlers = (formJSON) => {
    let handlersList = [];
    formJSON.forEach(section => {
        section.sectionFields.forEach(subSection => {
            handlersList.push(subSection.handlerType);
        })
    })
    handlersList.push(HANDLER_PICKUP_ADDRESS_TYPE);
    handlersList.push(HANDLER_DELIVERY_ADDRESS_TYPE);
    return handlersList;
}

export const safeJSONStringify = (jsonTarget) => {
    let cache = [];
    let str = JSON.stringify(jsonTarget, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.includes(value)) return;
            cache.push(value);
        }
        return value;
    });
    return str;
}

export const orderNumberGenerator = (len) => {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = len; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export const ensureRequiredFields = (data) => {
    if (!data["orderNumber"])
        data["orderNumber"] = orderNumberGenerator(15);

    if (!data["customerName"])
        data["customerName"] = "";

    if (!data["customerPhoneNumber"])
        data["customerPhoneNumber"] = "";

    if (!data["customerEmail"])
        data["customerEmail"] = "";

    if (!data["customerAddress"])
        data["customerAddress"] = "";

    if (!data["restaurantAddress"])
        data["restaurantAddress"] = "";

    if (!data["restaurantName"])
        data["restaurantName"] = "";

    if(!data["totalOrderCost"]) {
        let total = 0
        total = (data["deliveryFee"] || 0) + (data["tips"] || 0);
        data["totalOrderCost"] = total;
    }

    if (data["restaurantContactName"]) {
        data["restaurantName"] += " (" + data["restaurantContactName"] + ")";
        delete data["restaurantContactName"];
    }

    let delivery = defaultDeliveryTime(data["expectedPickupDate"], data["expectedPickupTime"]);
    if(!data["expectedDeliveryTime"]) data["expectedDeliveryTime"] = delivery.time;
    if(!data["expectedDeliveryDate"]) data["expectedDeliveryDate"] = delivery.date;
    let utcDelivery = dateTimeFormatterToUTC(dateFormatYYYYFirst(data["expectedDeliveryDate"]), data["expectedDeliveryTime"]);
    data["expectedDeliveryTime"] = utcDelivery.time;
    data["expectedDeliveryDate"] = utcDelivery.date;
    let pickup = defaultPickUpTime();
    if(!data["expectedPickupTime"]) data["expectedPickupTime"] = pickup.time;
    if(!data["expectedPickupDate"]) data["expectedPickupDate"] = pickup.date;
    let utcPickup = dateTimeFormatterToUTC(dateFormatYYYYFirst(data["expectedPickupDate"]), data["expectedPickupTime"]);
    data["expectedPickupTime"] = utcPickup.time;
    data["expectedPickupDate"] = utcPickup.date;

    if (data["customerLastName"]) {
        data["customerName"] += " " + data["customerLastName"];
        delete data["customerLastName"];
    }

    if (data["restaurantLastName"]) {
        data["restaurantName"] += " " + data["restaurantLastName"];
        delete data["restaurantLastName"];
    }

    if (data["pickUpCompanyName"]) {
        data["restaurantName"] += " (" + data["pickUpCompanyName"] + ")";
        delete data["pickUpCompanyName"];
    }

    if (data["itemType"]) {
        const name =  data["itemType"];
        const quantity = data["numberOfItems"];
        const itemSize =  data["itemSize"];
        const unitPrice = data?.["itemPrice"] ?? 0;
        const detail = data["serviceType"];
        const orderItem = getItemJson(name, quantity, itemSize, unitPrice, detail);
        delete data["itemType"];
        delete data["itemSize"];
        delete data["serviceType"];
        delete data["numberOfItems"];
        delete data["itemPrice"];
        data["orderItem"] = orderItem;
    }

    //Gateway and platform
    data["gateway"] = "INTERNAL";
    data["platform"] = "ORDER_FORMS";

    return data;
}

export const getItemJson = (name, quantity = 1, itemSize, unitPrice, detail) => {
    let addOns = [itemSize];
    if(itemSize === undefined) addOns = [];
    let item = {
        name: name,
        quantity: quantity,
        addOns: addOns,
        unitPrice: unitPrice,
        detail: detail
    }
    let items = [];
    items.push(item);
    try {
        return JSON.stringify(items);
    } catch (e) {
        return safeJSONStringify(items);
    }
}