export const HANDLER_NUMBER_OF_EXTRA_ITEMS_2 = "HANDLER_NUMBER_OF_EXTRA_ITEMS_2";

export default (state = 0, action) => {
    switch (action.type) {
        case HANDLER_NUMBER_OF_EXTRA_ITEMS_2:
            return action.payload;
        default:
            return state;
    }
}
