import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormPageWrapper from "../common/formPageWrapper";
import {
    DATE_INPUT_FIELD,
    EMAIL_INPUT_FIELD,
    fieldJSONCreator, LAYOUT_DEFAULT, sectionTitleJSONCreator, SELECTOR_INPUT_FIELD, TEXT_INPUT_FIELD,
    TEXTAREA_ADDRESS_FIELD,
    TEXTAREA_FIELD, VALUE_INPUT_FIELD
} from "../../util/formBuilderHelper";
import { HANDLER_CUSTOMER_PHONE_NUMBER } from "../../reducers/customerPhoneNumberReducer";
import { HANDLER_RESTAURANT_ADDRESS } from "../../reducers/restaurantAddressReducer";
import { HANDLER_CUSTOMER_ADDRESS } from "../../reducers/customerAddressReducer";
import { HANDLER_RESTAURANT_PHONE_NUMBER } from "../../reducers/restaurantPhoneNumberReducer";
import { HANDLER_RESTAURANT_NAME } from "../../reducers/restaurantNameReducer";
import { HANDLER_CUSTOMER_NAME } from "../../reducers/customerNameReducer";
import { useDispatch } from "react-redux";
import { findCompanyDetails } from "../../actions/companyDetailsAction";
import { HANDLER_TOTAL_ORDER_COST } from "../../reducers/totalOrderCostReducer";
import { useTranslation } from "react-i18next";
import FORM_LOCALE_KEYS from "../../resources/locale/keys";
import { HANDLER_DELIVERY_INSTRUCTION } from "../../reducers/deliveryInstructionReducer";
import { OrderEmailTemplate4B } from "../../util/emailTemplates/orderEmailTemplate4B";
import { onlyDispatcherMail } from "../../util/emailTemplateDecorator/onlyDispatcherMail";
import { useDeliveryCompanyInfos } from "../customHooks/deliveryComapnyInfosHook";
import { HANDLER_EXPECTED_PICKUP_DATE } from "../../reducers/expectedPickUpDateReducer";
import { HANDLER_EXPECTED_DELIVERY_DATE } from "../../reducers/expectedDeliveryDateReducer";
import { HANDLER_CUSTOMER_EMAIL } from "../../reducers/customerEmailReducer";
import { HANDLER_ITEM_SIZE } from "../../reducers/itemSizeReducer";
import { HANDLER_ITEM_TYPE } from "../../reducers/itemTypeReducer";
import { HANDLER_PAYMENT_METHOD } from "../../reducers/paymentMethodReducer";
import { HANDLER_DELIVERY_FEE } from "../../reducers/deliveryFeeReducer";

// for nexmoveltd@gmail.com

const OrderForm35 = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [pickupName, pickupAddress, pickupPhone] = useDeliveryCompanyInfos();

    const orderForm4BJson = [
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.PICK_UP_FORM)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Name of Business/Company", '', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_NAME, null, true, {initialValue: pickupName}),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Phone No", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_PHONE_NUMBER, null, true, {initialValue: pickupPhone}),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Address", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_RESTAURANT_ADDRESS, null, true, {initialValue: pickupAddress}),
                fieldJSONCreator(DATE_INPUT_FIELD, "Pickup Date", '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_PICKUP_DATE)
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator(t(FORM_LOCALE_KEYS.DELIVER_TO)),
            sectionFields: [
                fieldJSONCreator(TEXT_INPUT_FIELD, "Name", '', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_NAME),
                fieldJSONCreator(TEXT_INPUT_FIELD, "Phone No", '(000) 000-00-00', true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_PHONE_NUMBER),
                fieldJSONCreator(EMAIL_INPUT_FIELD, "Email", '', false, LAYOUT_DEFAULT, HANDLER_CUSTOMER_EMAIL),
                fieldJSONCreator(TEXTAREA_ADDRESS_FIELD, "Address", t(FORM_LOCALE_KEYS.ENTER_LOCATION), true, LAYOUT_DEFAULT, HANDLER_CUSTOMER_ADDRESS),
                fieldJSONCreator(DATE_INPUT_FIELD, "Delivery Date", '', true, LAYOUT_DEFAULT, HANDLER_EXPECTED_DELIVERY_DATE),
            ]
        },
        {
            sectionHeader: sectionTitleJSONCreator("Package Details"),
            sectionFields: [
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Size of Item", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_SIZE, [
                    {label: "Small", value: "Small"},
                    {label: "Medium", value: "Medium"}
                ]),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Type of Item", '', true, LAYOUT_DEFAULT, HANDLER_ITEM_TYPE, [
                    {label: "Perishables", value: "Perishables"},
                    {label: "Documents", value: "Documents"},
                    {label: "Parcels", value: "Parcels"}
                ]),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Cost of Item", '', true, LAYOUT_DEFAULT, HANDLER_TOTAL_ORDER_COST),
                fieldJSONCreator(VALUE_INPUT_FIELD, "Delivery Fees", '', true, LAYOUT_DEFAULT, HANDLER_DELIVERY_FEE),
                fieldJSONCreator(TEXTAREA_FIELD, "Delivery Instructions", '', false, LAYOUT_DEFAULT, HANDLER_DELIVERY_INSTRUCTION),
                fieldJSONCreator(SELECTOR_INPUT_FIELD, "Payment Method", '', false, LAYOUT_DEFAULT, HANDLER_PAYMENT_METHOD, [
                    {label: "Cash on Delivery", value: "cash"},
                    {label: "Online Transfer", value: "credit_card"},
                ]),
            ]
        },
    ]

    useEffect(() => {
        dispatch(findCompanyDetails(params.id));
    }, []);

    const template = onlyDispatcherMail(OrderEmailTemplate4B)

    const preProcessFunc = (data) => {
        const unitPrice = data["totalOrderCost"] - data["deliveryFee"];
        data["itemPrice"] = unitPrice;
        return data;
    }

    return (
        <>
            <FormPageWrapper FullFormConfigJSON={orderForm4BJson} emailTemplateFunc={template} preProcessFunc={preProcessFunc}/>
        </>
    )
}

export default OrderForm35;
